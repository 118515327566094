import * as React from "react";
import Header from "../../../components/Header";
import Button from "@mui/material/Button";
import CloudDownloadOutlined from "@mui/icons-material/CloudDownloadOutlined";
import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./style.css";
import CardInit from "../../../components/CardInit/CardInit";
import { MdDownloading } from "react-icons/md";
import Footer from "../../../components/Footer";
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
import "@cyntler/react-doc-viewer/dist/index.css";

require("../../tokenRenewal");

const fileTypes = ["XLSX"];
const templateUrl = process.env.REACT_APP_FORECAST_URL;

function ForecastUpload(props) {
  const [data, setData] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const analysis = JSON.parse(localStorage.getItem("analysis"));
  const navigate = useNavigate();
  const forecastCompleted = analysis ? analysis?.statusForecastCompleted : null;
  const [selectedFile, setSelectedFile] = useState(null);
  const [dataMccs, setDataMccs] = useState([]);

  const readExcel = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const sheetName = workbook.SheetNames[1];
      const worksheet = workbook.Sheets[sheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      const mccs = jsonData
        .slice(34)
        .map((item) => item["Informações Empresa"])
        .filter(Boolean)
        .map((mcc) => ({ mcc }));
      setData(jsonData);
      setDataMccs(mccs);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileUpload = (file) => {
    if (file.name.endsWith(".xlsx")) {
      setSelectedFile(file);
      readExcel(file);
    } else {
      toast.error("Tipo de arquivo não suportado.");
    }
  };

  const handleSave = async () => {
    if (!data.length) {
      toast.error("Nenhum dado carregado para envio.");
      return;
    }

    if (!selectedFile) {
      toast.error("Nenhum arquivo selecionado para envio.");
      return;
    }

    setIsSaving(true);
    const valor = data[34]?.["Informações Empresa"];
    const valorMcc = data[33]?.["Informações Empresa"];

    if (
      !valor ||
      !valorMcc ||
      valor.toString().length < 3 ||
      valorMcc !== "MCC"
    ) {
      alert(
        "Por favor, verifique o preenchimento do documento e se está utilizando o template disponibilizado."
      );
      console.error(
        "O documento não tem MCC ou não condiz com o formato do Template"
      );
      return;
    }
    try {
      const formData = new FormData();
      formData.append("files", selectedFile);
      const analysisId = analysis?.id;
      if (!analysisId) {
        console.error("ID da análise não encontrado.");
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/forecast/upload/${analysisId}`,
        formData,
        {
          headers: {
            Authorization: props.token,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const responseMccs = await axios.post(
        `${process.env.REACT_APP_API_URL}/forecast/${analysisId}`,
        dataMccs,
        {
          headers: {
            Authorization: props.token,
          },
        }
      );

      toast.success("Forecast enviado com sucesso!");
      navigate("/homeregistration/");
    } catch (error) {
      console.error(error);
      toast.error(
        error.response?.data?.message ||
          "Erro ao enviar o Forecast. Verifique os dados e tente novamente."
      );
    } finally {
      setIsSaving(false);
    }
  };

  if (isUploading) {
    return <CenteredCircularProgress />;
  }

  return (
    <>
      <Header name={"Forecast"} token={props.token} />
      <div className="pageForecast">
        <div className="forecastContainer">
          <div className="title">
            <h2>Forecast</h2>

            <>
              <p>
                Gostaríamos de pedir sua colaboração no preenchimento do
                forecast, pois ele será utilizado para definir a composição da
                garantia com a EntrePay. Por meio deste documento, saberemos os
                segmentos de atuação dos seus clientes e as informações
                financeiras.
              </p>
              <p>
                Obs: É possível realizar outro envio de Forecast, mesmo após o
                primeiro envio.
              </p>
            </>
          </div>

          <div className="infos">
            <CardInit
              title={"1. Faça download do template"}
              description={
                "Clique aqui para realizar o download do Template do Forecast."
              }
              icon={<MdDownloading />}
              onClick={() => {
                const url = templateUrl;
                const a = document.createElement("a");
                a.href = url;
                a.download = url.split("/").pop();
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }}
              actionName={"Baixar"}
            />
            <div>
              <FileUploader
                handleChange={handleFileUpload}
                name="file"
                types={fileTypes}
                children={
                  <CardInit
                    title={
                      "2. Preencha o template com os dados solicitados e, em seguida, faça o upload do documento aqui!"
                    }
                    description={
                      "Arraste ou clique para buscar o documento preenchido."
                    }
                    icon={<CloudDownloadOutlined />}
                    className={`cardUpload ${
                      !selectedFile && forecastCompleted === "COMPLETO"
                        ? "uploaded"
                        : ""
                    }`}
                    actionName={
                      !selectedFile
                        ? forecastCompleted === "COMPLETO"
                          ? "Forecast enviado"
                          : "Enviar"
                        : " "
                    }
                    upload={
                      selectedFile ? (
                        <div className="fileInfo">
                          <MdDownloading />
                          <p>{selectedFile.name}</p>
                        </div>
                      ) : null
                    }
                  ></CardInit>
                }
              />

              {selectedFile && (
                <p className="uploadedMessage">
                  {" "}
                  Documento "{selectedFile.name}" carregado. Clique em "Enviar"
                  ao final da página para submeter o Forecast.
                </p>
              )}
            </div>
          </div>
          <div className="forecastFooter">
            <div className="buttonsForecast">
              <Button
                sx={{
                  backgroundColor: "#e5007e",
                  border: "solid 1px",
                  borderColor: "#e5007e",
                  shadow: "none",
                  "&:hover": {
                    backgroundColor: "#e5007e",
                    color: "white",
                  },
                }}
                variant="contained"
                onClick={handleSave}
              >
                Enviar
              </Button>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default ForecastUpload;
