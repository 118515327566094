import * as React from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { styled } from "@mui/material/styles";
import Header from "../../../components/Header";
import { Typography, Grid, Card } from "@mui/material";
import { FileUploader } from "react-drag-drop-files";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { MdDownloading } from "react-icons/md";
import { MdDownloadDone } from "react-icons/md";

import {
  documentosPSP,
  documentosVAR,
  sociosVAR,
  sociosPSP,
  compliancePSP,
  requiredDocumentsVAR,
  requiredDocumentsPSP,
} from "../../../utils/UploadsArray";
import { usePartners } from "../../../context/partnerContext";
import { ButtonStyle, SendButtonStyle } from "../Form/FormPspVar";
import FooterButtons from "../../../components/FooterButtons";
import CenteredCircularProgress from "../../../components/CenteredCircularProgress";
import "./style.css";
import Footer from "../../../components/Footer";
import { keyBy } from "lodash";
require("../../tokenRenewal");

const CardStyled = styled(Card)({
  width: "70%",
  padding: 0,
  borderStyle: "dotted",
  borderColor: "#e5007e",
  marginTop: 5,
});

function Uploads({ token }) {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState({});
  const analysis = JSON.parse(localStorage.getItem("analysis"));
  const navigate = useNavigate();
  const childRef = useRef(null);
  const { partners } = usePartners();
  const [uploads, setUploads] = useState([]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState(false);
  const [button, setButton] = useState("");
  const [uploadProgress, setUploadProgress] = useState("");

  const handleTypeError = () => {
    toast.error(
      "Formato de arquivo não suportado! Por favor, carregue um arquivo válido.",
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleUploadChange = async (name, file) => {
    const temp = { ...files };
    temp[name] = file;
    setFiles(temp);
    setTimeout(() => {
      onClickSave(name, file);
    }, 1000);
  };

  const getUploads = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + "/documentsupload/" + analysis?.id,
        {
          headers: {
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );
      const uploadsMap = {};
      response.data.forEach((upload) => {
        uploadsMap[upload.documentType] = {
          uploadName: upload.documentType,
          fileName: upload.fileName,
        };
      });
      const db = Object.values(uploadsMap);
      setUploads(db);
    } catch (error) {
      console.error(error, "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUploads();
  }, []);

  const allRequiredDocumentsUploaded = (
    requiredDocuments,
    currentUploads,
    files
  ) => {
    let uploadedDocuments = [
      ...currentUploads.map((upload) => upload.uploadName),
    ];

    Object.keys(files).forEach((key) => {
      const fileList = files[key];
      for (let i = 0; i < fileList.length; i++) {
        uploadedDocuments.push(`${key}`);
      }
    });

    uploadedDocuments = Array.from(new Set(uploadedDocuments));

    return requiredDocuments.every((requiredDoc) =>
      uploadedDocuments.some((uploadedDoc) => uploadedDoc.includes(requiredDoc))
    );
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const uploadFilesWithDelay = async (
    files,
    delay = 1000,
    isFinalize = false
  ) => {
    const filesKeySet = Object.keys(files);
    const totalFiles = filesKeySet.reduce(
      (sum, key) => sum + (files[key]?.length || 0),
      0
    );
    let uploadedFilesCount = 0;

    let hasFiles = false;

    for (const index in filesKeySet) {
      const key = filesKeySet[index];
      const uploadFiles = files[key];
      const data = new FormData();
      data.append(
        "complete",
        isFinalize ? index == filesKeySet.length - 1 : false
      );

      if (uploadFiles && uploadFiles.length > 0) {
        hasFiles = true;
        for (let i = 0; i < uploadFiles.length; i++) {
          data.append("files", uploadFiles[i]);
        }
      }

      try {
        await axios.post(
          process.env.REACT_APP_API_URL +
            "/documentupload/" +
            analysis?.id +
            "/" +
            key,
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );

        uploadedFilesCount += uploadFiles.length;
        setUploadProgress((uploadedFilesCount / totalFiles) * 100);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          await sleep(delay);
          try {
            await axios.post(
              process.env.REACT_APP_API_URL +
                "/documentupload/" +
                analysis?.id +
                "/" +
                key,
              data,
              {
                headers: {
                  Authorization: token,
                },
              }
            );
            uploadedFilesCount += uploadFiles.length;
            setUploadProgress((uploadedFilesCount / totalFiles) * 100);
          } catch (retryError) {
            throw retryError;
          } finally {
            setLoading(false);
          }
        } else {
          throw error;
        }
      }

      await sleep(delay);
    }

    if (!hasFiles) {
      const data = new FormData();
      data.append("complete", isFinalize);

      try {
        await axios.post(
          process.env.REACT_APP_API_URL +
            "/documentupload/" +
            analysis?.id +
            "/" +
            "complete",
          data,
          {
            headers: {
              Authorization: token,
            },
          }
        );
      } catch (error) {
        if (error.response && error.response.status === 429) {
          await sleep(delay);
          try {
            await axios.post(
              process.env.REACT_APP_API_URL +
                "/documentupload/" +
                analysis?.id +
                "/" +
                "complete",

              data,
              {
                headers: {
                  Authorization: token,
                },
              }
            );
          } catch (retryError) {
            throw retryError;
          } finally {
            setLoading(false);
          }
        } else {
          throw error;
        }
      }
      await sleep(delay);
    }
  };

  const onClickSave = async (key, file) => {
    const data = new FormData();
    data.append("complete", false);
    data.append("files", file[0]);

    await callApi(key, data);
  };

  const callApi = async (key, data) => {
    try {
      await axios.post(
        process.env.REACT_APP_API_URL +
          "/documentupload/" +
          analysis?.id +
          "/" +
          key,
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );
      toast.success("Arquivo carregado.");
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 413) {
        toast.error("Tamanho do arquivo excede o limite");
      } else {
        toast.error("Erro ao salvar arquivos, por favor tente novamente");
      }
    }
  };

  const onClickNext = async () => {
    setLoading(true);
    setButton("save");
    try {
      await uploadFilesWithDelay(files, 1000, false);

      toast.success("Arquivos salvos com sucesso.");
      navigate("/homeregistration");
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 413) {
        toast.error("Tamanho do arquivo excede o limite");
      } else {
        toast.error("Erro ao salvar arquivos, por favor tente novamente");
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickFinalize = async () => {
    setButton("finalize");
    setLoading(true);
    try {
      await getUploads();

      let hasPartners = partners.length > 0;

      const requiredDocuments =
        analysis?.clientType === "VAR"
          ? requiredDocumentsVAR
          : requiredDocumentsPSP;

      const idsToFilter = ["Partner", "RG", "CPF", "tax"];

      let filteredDocuments = requiredDocuments;

      if (!hasPartners) {
        filteredDocuments = requiredDocuments.filter(
          (id) => !idsToFilter.some((filterId) => id.includes(filterId))
        );
      }

      const allUploaded = allRequiredDocumentsUploaded(
        filteredDocuments,
        uploads,
        files
      );
      setOpen(false);

      if (!allUploaded) {
        toast.error(
          "Por favor, faça o envio de todos os documentos obrigatórios."
        );
        return;
      }

      await uploadFilesWithDelay(files, 1000, true);

      toast.success("O envio dos arquivos foi feito com sucesso.");
      navigate("/homeregistration");
      setOpen(false);
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 413) {
        toast.error("Tamanho do arquivo excede o limite");
      } else {
        toast.error("Erro ao enviar arquivos, por favor tente novamente");
      }
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <>
        <CenteredCircularProgress
          message={message}
          button={button}
          progress={uploadProgress}
        />
      </>
    );
  }

  const fileTypes = [
    "PDF",
    "DOC",
    "DOCX",
    "XLS",
    "XLSX",
    "PNG",
    "JPG",
    "JPEG",
    "CSV",
    "ZIP",
  ];

  const renderFileUploader = ({ name, id, description }) => {
    const uploadedFile = uploads.find((upload) => upload.uploadName === id);

    return (
      <Grid item xs={6} key={id}>
        <FileUploader
          onTypeError={handleTypeError}
          handleChange={(file) => handleUploadChange(id, file)}
          name="file"
          types={fileTypes}
          multiple={true}
          children={
            <>
              <div className={`cardUpload ${uploadedFile ? "uploaded" : ""}`}>
                {" "}
                <div className="cardIcon flex">
                  <CloudUploadOutlinedIcon />
                </div>
                <div>
                  <p className="titleUpCard">{name}</p>
                  {description && (
                    <p className="descriptionUpCard">{description}</p>
                  )}

                  <div className="uploadControl">
                    {files?.[id]?.length > 0 ? (
                      Object.keys(files?.[id]).map((v) => {
                        const uploadedFile = files?.[id][v];
                        return (
                          <div className="uploadInfo flex">
                            <MdDownloading style={{}} />
                            <p key={v}>{uploadedFile.name}</p>
                          </div>
                        );
                      })
                    ) : uploadedFile ? (
                      <div className="uploadInfo">
                        <MdDownloadDone style={{ color: "green" }} />
                        <p>{uploadedFile.fileName}</p>
                      </div>
                    ) : (
                      <div className="uploadInfo"></div>
                    )}
                  </div>
                </div>
              </div>
            </>
          }
        />
      </Grid>
    );
  };
  const renderSocios = (socios, partnerIndex) => {
    return socios.map((doc) => {
      const uploadedFile = uploads.find(
        (upload) => upload.uploadName === `${doc.id}${partnerIndex}`
      );
      return (
        <Grid item xs={6} key={`${doc.id}${partnerIndex}`}>
          <FileUploader
            handleChange={(file) =>
              handleUploadChange(`${doc.id}${partnerIndex}`, file)
            }
            name="file"
            onTypeError={handleTypeError}
            types={fileTypes}
            multiple={true}
            children={
              <>
                <div className={`cardUpload ${uploadedFile ? "uploaded" : ""}`}>
                  <div className="cardIcon flex">
                    <CloudUploadOutlinedIcon />
                  </div>
                  <div>
                    <p className="titleUpCard">{doc.name}</p>
                    {doc.description && (
                      <p className="descriptionUpCard">{doc.description}</p>
                    )}
                    <div className="uploadControl">
                      {files?.[`${doc.id}${partnerIndex}`]?.length > 0 ? (
                        Object.keys(files?.[`${doc.id}${partnerIndex}`]).map(
                          (v) => {
                            const uploadedFile =
                              files?.[`${doc.id}${partnerIndex}`][v];
                            return (
                              <div className="uploadInfo">
                                <MdDownloading style={{}} />
                                <p key={v}>{uploadedFile.name}</p>
                              </div>
                            );
                          }
                        )
                      ) : uploadedFile ? (
                        <div className="uploadInfo">
                          <MdDownloadDone style={{ color: "green" }} />
                          <p>{uploadedFile.fileName}</p>
                        </div>
                      ) : (
                        <div className="uploadInfo"></div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            }
          />
        </Grid>
      );
    });
  };

  return (
    <>
      <Header name="uploads" token={token} />
      <div className="page">
        <div className="uploadContainer">
          <div>
            <div className="titleUp">
              {" "}
              <h2 style={{ textAlign: "initial" }}>Uploads</h2>
              {analysis?.clientType === "PSP" ? (
                <>
                  <p>
                    Aqui você faz o upload dos documentos empresariais,
                    societários, de compliance, PLD/FT, risco e{" "}
                    <i>chargeback</i>.{" "}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Aqui você faz o upload dos documentos empresariais e
                    societários.
                  </p>
                </>
              )}
              <p>
                * Para fazer o envio de mais de um documento por campo, envie em
                formato ZIP.
              </p>
            </div>
            <div className="cardsUp">
              {(() => {
                let documentos, sociosConfig, complianceDocs;
                let sociosTitle = "Documentos Societários";
                let complianceTitle =
                  "Documentos de compliance, PLD/FT, risco e <i>chargeback</i>";

                if (analysis?.clientType === "VAR") {
                  documentos = documentosVAR;
                  sociosConfig = sociosVAR;
                  complianceDocs = null;
                } else if (analysis?.clientType === "PSP") {
                  documentos = documentosPSP;
                  sociosConfig = sociosPSP;
                  complianceDocs = compliancePSP;
                }

                return (
                  <>
                    <div className="boxSection">
                      <h3 className="uploadsTitle">Documentos Empresariais </h3>
                      <div className="boxContainer">
                        {documentos?.map(renderFileUploader)}
                      </div>
                    </div>

                    {partners && partners.length > 0 && (
                      <div className="boxSection">
                        <div style={{ width: "100%" }}>
                          <h3 className="uploadsTitle"> {sociosTitle} </h3>
                        </div>

                        <div className="boxContainer">
                          {partners.map((partner, index) => (
                            <React.Fragment key={index}>
                              <Typography
                                sx={{ fontWeight: "bold", width: "100%" }}
                              >
                                Documentos do sócio {partner?.fullName}
                              </Typography>
                              {partner?.cpf?.length === 11
                                ? renderSocios(sociosConfig.cpf11, index)
                                : renderSocios(sociosConfig.cpfMais11, index)}
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className="boxSection">
                      {complianceDocs && (
                        <>
                          <Grid item xs={12}>
                            <h3
                              className="uploadsTitle"
                              dangerouslySetInnerHTML={{
                                __html: complianceTitle,
                              }}
                            ></h3>
                          </Grid>
                          <div className="boxContainer">
                            {complianceDocs.map(renderFileUploader)}
                          </div>
                        </>
                      )}
                    </div>
                  </>
                );
              })()}
            </div>
          </div>
          <div className="uploadFooter">
            <FooterButtons
              ButtonStyle={ButtonStyle}
              SendButtonStyle={SendButtonStyle}
              onClickNext={onClickNext}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
              open={open}
              onClickFinalize={onClickFinalize}
              textSend="os arquivos"
              sinalization="* Atenção aos documentos sinalizados como Obrigatórios. "
            />
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}

export default Uploads;
